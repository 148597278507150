import styled from 'styled-components'
import { colours, size } from '@variables'

export const HeaderSection = styled.header``

export const HeadWrap = styled.div`
  background: ${colours.pgwarmgrey};
  display: flex;
  padding: 0.5em 1em 0.2em 1em;
  @media (min-width: ${size.tablet}) {
    padding: 1em 1em 0.2em 1em;
  }
`

export const LogoLink = styled.a``
export const Image = styled.img``

export const HeadLogo = styled.div`
  flex: 1;
  img {
    width: 100%;
    margin-bottom: 0;
  }
`

export const HeadSpace = styled.div`
  flex: 2;
  @media (min-width: ${size.tablet}) {
    flex: 4;
  }
  @media (min-width: ${size.desktop}) {
    flex: 6;
  }
`
