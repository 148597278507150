import styled from 'styled-components'

export const StyledTitle = styled.h1`
  color: white;
  background: #5a5a5a;
  box-shadow: 0px 8px 0px #8bc840;
  padding: 6px 12px;
  width: auto;
  margin-top: 0;
  margin-bottom: 22px;
  display: inline-block;
`
