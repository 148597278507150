export const colours = {
    pgwhite: '#FFFFFF',
    pgblack: '#202020',
    pggreen: '#8BC840',
    pgcoolgrey: '#A9A9A9',
    pgwarmgrey: '#5A5A5A',
    pggreenhover: '#689630',
    twitter: '#1dcaff',
    facebook: '#3b5998',
    socialicon: '#dddddd',
};

export const sizes = {
  monitor: 1600,
  desktop: 992,
  tablet: 768,
  phone: 544,
  tiny: 340,
  none: 320,
};
export const size = {
  monitor: '1600px',
  desktop: '992px',
  tablet: '768px',
  phone: '544px',
  tiny: '340px',
  none: '320px',
};

export const device = {
  monitor: `(min-width: 1600px)`,
  desktop: `(min-width: ${size.desktop})`,
  tablet: `(min-width: ${size.tablet})`,
  phone: `(min-width: ${size.phone})`,
  tiny: `(min-width: ${size.tiny})`,
  none: `(min-width: ${size.none})`
};

export const Margin = {
  spacer: '1rem',
};