import React from 'react'
import Navigation from '../Navigation'
import { SowerbySVG } from '@components/Icons'
import { HeaderSection, HeadWrap, HeadLogo, LogoLink, HeadSpace } from './styles'

const Header = () => {
  return (
    <HeaderSection>
      <HeadWrap>
        <HeadLogo>
          <LogoLink href="/">
            <SowerbySVG />
          </LogoLink>
        </HeadLogo>
        <HeadSpace />
      </HeadWrap>
      <Navigation />
    </HeaderSection>
  )
}

export default Header
