import React from 'react'
import { TwitterSVG, FacebookSVG } from '@components/Icons'
import { Link } from 'gatsby'
import { MainFooter, AddressStyle, FooterLink, SocialWrap, SocialBox, FooterHeading, SocialLink, P } from './styles'

const Footer = () => (
  <MainFooter>
    <AddressStyle>
      <P>
        Copyright 2017 - {new Date().getFullYear()}{' '}
        <FooterLink href="https://www.facebook.com/groups/636021413163109 " target="_blank ">
          Sowerby Bridge Business Network
        </FooterLink>
      </P>
      <P>
        Website Designed and Developed By{' '}
        <FooterLink href="http://www.waywardmedia.co.uk " target="_blank ">
          Wayward Media
        </FooterLink>
      </P>
      <P>
        For more information on things to see and do in Calderdale please{' '}
        <FooterLink href="http://www.visitcalderdale.com " target="_blank ">
          Visit Calderdale
        </FooterLink>
      </P>
      <Link to="/getintouch/">Contact Us</Link>
      <P>
        <FooterLink href="https://www.insowerbybridge.co.uk/sitemap.xml " target="_blank ">
          Site Map
        </FooterLink>
      </P>
    </AddressStyle>
    <SocialWrap>
      <SocialBox>
        <FooterHeading>Follow us on Social Media</FooterHeading>
        <SocialLink href="http://www.facebook.com/insowerbybridge " target="_blank ">
          <FacebookSVG />
        </SocialLink>
        <SocialLink href="https://twitter.com/insowerbybridge " target="_blank ">
          <TwitterSVG />
        </SocialLink>
      </SocialBox>
    </SocialWrap>
  </MainFooter>
)

export default Footer
