import styled from 'styled-components'
import { media } from '@media'
import { colours } from '@variables'
import { Link } from 'gatsby'

export const Nav = styled.nav`
  a {
    color: ${colours.pgwhite};
  }
`

export const Toggle = styled.span`
  clear: both;
  display: block;
  cursor: pointer;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: ${colours.pgwhite};
  background-color: ${({ open }) => (open ? colours.pgblack : colours.pggreen)};
  padding-left: 1em;

  ${media.tablet`
    display: none;
  `};
`

export const FirstLevel = styled.ul`
  flex-direction: column;
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
  display: ${({ open }) => (open ? 'flex' : 'none')};

  background: ${colours.pggreen};
  ${media.tablet`
   display: flex;
   flex-direction: row;
  `};
`

export const SecondLevel = styled.ul`
  margin-left: -1rem;
  margin-bottom: 0;
  list-style: none;
  display: none;
  width: 100%;
  background: ${colours.pgwarmgrey};
  position: absolute;
  ${media.tablet`
   margin-left: -1rem;
   width: 250px;
  `}
`

export const FirstLevelLink = styled(Link)`
  display: block;
  padding-left: 1em;
  padding-right: 1em;
  height: 40px;
  line-height: 40px;
  width: 100%;
  &:hover {
    background-color: ${colours.pgblack};
    ul {
      display: flex;
      flex-direction: column;
    }
  }
  ${media.tablet`
   width: 100%;
   display: inline-block;
`}
`
export const SecondLevelLink = styled(Link)`
  list-style: none;
  display: block;
  padding-left: 1em;
  padding-right: 1em;
  &:hover {
    background-color: ${colours.pgblack};
  }
`

export const Li = styled.li`
  display: inline-block;
`
