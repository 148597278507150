import React from 'react'
import Helmet from 'react-helmet'

export function Seo({ url, pathname, title, description }) {
  return (
    <Helmet
      title={title}
      meta={[
        { name: 'description', content: description },
        { name: 'keywords', content: 'Sowerby Bridge, Halifax, Calderdale, West Yorkshire' },

        { name: 'geo.position', content: '53.706709053;-1.91341101' },
        { name: 'geo.region', content: 'GB' },
        { name: 'geo.placename', content: 'Sowerby Bridge, United Kingdom' },
        { name: 'ICBM', content: '53.706709053, -1.91341101' },
        { name: 'DC.title', content: 'Sowerby Bridge' },

        { property: 'og:site_name', content: 'In Sowerby Bridge' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:type', content: 'article' },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:latitude', content: '53.706709053' },
        { property: 'og:longitude', content: '-1.91341101' },
        { property: 'og:locality', content: 'Sowerby Bridge' },
        { property: 'og:region', content: 'West Yorkshire' },
        { property: 'og:country-name', content: 'UK' },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@insowerbybridge' },
        { name: 'twitter:creator', content: '@insowerbybridge' },
        { name: 'twitter:description', content: description },
      ]}
    />
  )
}

Seo.defaultProps = {
  url: 'https://www.insowerbybridge.co.uk',
  pathname: '',
  title: 'Sowerby Bridge The Town and Business Guide For Residents and Visitors',
  description: 'Sowerby Bridge is a working town on the banks of the River Ryburn and Calder. A regular port of call for Canal Boats on the Rochdale Canal.',
}
