import styled from 'styled-components'
import { media } from '@media'
import { colours, Margin } from '@variables'

export const MainFooter = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  background: ${colours.pgwarmgrey};
  color: ${colours.pgwhite};
  padding: ${Margin.spacer};
  ${media.tablet`
     flex-direction: row;
   `};
`

export const AddressStyle = styled.div`
  flex: 1;
  h3 {
    font-weight: bold;
    margin-top: 0;
  }
  .webfooter {
    margin-top: ${Margin.spacer};
  }
`

export const SocialWrap = styled.div`
  display: flex;
  flex: 1;
  height: auto;
  text-align: left;
  padding-left: 0;
  flex-direction: column;
  h3 {
    font-weight: bold;
    margin-top: 0;
  }

  ${media.phone`
      flex-direction: row;
      text-align: right;
      padding-top: ${Margin.spacer};
   `};
`

export const GrittyLogo = styled.div`
  flex: 1;
  order: 2;
  svg {
    width: 8em;
  }

  ${media.tablet`
      order: 1;
   `};
`

export const SocialBox = styled.div`
  flex: 3;
  order: 1;

  ${media.tablet`
      order: 2;
   `};

  .svgfbook {
    height: 40px;
    .svgfbookpath {
      fill: ${colours.socialicon};
      &:hover {
        fill: ${colours.facebook};
      }
    }
  }

  .svgtwit {
    margin-left: 5px;
    height: 40px;
    .svgtwitpath {
      fill: ${colours.socialicon};
      &:hover {
        fill: ${colours.twitter};
      }
    }
  }
`

export const SocialLink = styled.a``

export const FooterHeading = styled.h4``

export const FooterLink = styled.a``

export const P = styled.p``
