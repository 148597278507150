import React, { useState } from 'react'
import { Nav, Toggle, FirstLevel, SecondLevel, FirstLevelLink, SecondLevelLink, Li } from './styles'

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const menuTrueFalse = () => setMenuOpen(!menuOpen)

  return (
    <Nav>
      <Toggle onClick={menuTrueFalse}>☰ Menu</Toggle>
      <FirstLevel open={menuOpen}>
        <Li>
          <FirstLevelLink to="/">Home</FirstLevelLink>
        </Li>
        <Li>
          <FirstLevelLink to="/news/">News</FirstLevelLink>
        </Li>
        <Li>
          <FirstLevelLink to="/events/">Events</FirstLevelLink>
        </Li>
        <Li>
          <FirstLevelLink to="/visit/">
            Visit ▼
            <SecondLevel>
              <Li>
                <SecondLevelLink to="/businesses/accommodation">Places to Stay</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/businesses/eatingout">Places to Eat</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/businesses/retail">Where to Shop</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/businesses/marketstall">Sowerby Bridge Market</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/visit/sowerbybridgepoolandfitness">Sowerby Bridge Pool</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/visit/parking">Car Parking</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/visit/howtogethere">How to Get Here</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/visit/cashmachines">Cash Machines</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/gallery/">Gallery</SecondLevelLink>
              </Li>
            </SecondLevel>
          </FirstLevelLink>
        </Li>
        <Li>
          <FirstLevelLink to="/explore/">
            Explore ▼
            <SecondLevel>
              <Li>
                <SecondLevelLink to="/explore/aboutsowerbybridge">About Sowerby Bridge</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/explore/calderhebblenavigation">Calder and Hebble</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/explore/historicinterest">Historic Interests</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/explore/nature">Nature and Wildlife</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/explore/thewharf">The Wharf</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/explore/walkingandcycling">Walking and Cycling</SecondLevelLink>
              </Li>
            </SecondLevel>
          </FirstLevelLink>
        </Li>
        <Li>
          <FirstLevelLink to="/community/">
            Community ▼
            <SecondLevel>
              <Li>
                <SecondLevelLink to="/community/churches">Churches</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/community/groups">Groups</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/community/schools">Schools</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/community/information">Information</SecondLevelLink>
              </Li>
              <Li>
                <SecondLevelLink to="/community/riverlevels">River Level Monitoring</SecondLevelLink>
              </Li>
            </SecondLevel>
          </FirstLevelLink>
        </Li>
        <Li>
          <FirstLevelLink to="/businesses/">Businesses</FirstLevelLink>
        </Li>
        <Li>
          <FirstLevelLink to="/getintouch/">Get in Touch</FirstLevelLink>
        </Li>
      </FirstLevel>
    </Nav>
  )
}

export default Navigation
