import React from 'react'
import Header from '@components/Header'
import Footer from '@components/Footer'
import { Seo } from '@components/Seo'
import { PageWrap, Main, TwoColumnWrap, TwoColumnOne, TwoColumnTwo, FlexGrow } from './styles'

const Layout = ({ children }) => (
  <PageWrap>
    <Seo />
    <Header />
    <Main>
      <TwoColumnWrap>
        <TwoColumnOne>{children}</TwoColumnOne>
        <TwoColumnTwo />
      </TwoColumnWrap>
    </Main>
    <FlexGrow />
    <Footer />
  </PageWrap>
)

export default Layout
