import styled from 'styled-components'
import { media } from '@media'
import { Margin } from '@variables'

export const PageWrap = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Main = styled.main`
  flex: 1;
  min-height: 40vh;
`

export const TwoColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop`
    flex-direction: row;
  `};
`

export const TwoColumnOne = styled.div`
  flex: 1;
  padding: ${Margin.spacer};
  ${media.desktop`
    flex: 3;
  `};
  ${media.monitor`
    flex: 2;
  `};
`

export const TwoColumnTwo = styled.div`
  flex: 1;
  padding: ${Margin.spacer};
  ${media.desktop`
    flex: 1;
  `}
  ${media.monitor`
    flex: 1;
  `};
`

export const FlexGrow = styled.div`
  flex-grow: 1;
`
